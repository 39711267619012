import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from '../utils/util.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as moment from 'moment/moment';
import { MessageService } from './message.service';
import { ToastService } from '../utils/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http:HttpClient, private router: Router, private ms: MessageService, private toastService: ToastService) { }
  isValidSession = false;

  myOrgUsers = [];

  currentUserFundPermissionReadOnly = false; //User has Only Read Access to Fund
  currentUserCompanyPermissionReadOnly = false; //User has Only Read Access to Company

  USER_BASED_ACCESS_TYPES = {
    FUND_WRITE_ACCESS : "FW", //Can be given Fund Write Access
    FUND_READ_ACCESS : "FR", //Can be given Fund Read Only Access
  }  

  createOrg(org) {
    // const url = 'http://localhost:8082/api/v1/um/org'
    
    // return this.http.post(url, org, {observe: "response"});

    return this.appHttpRequest("POST", "/org", org);
  }

  getOrgList(userId) {
    let url = '/orgs';

    if (userId !== null) {
        url = `/orgs/userId/${userId}`;
    }

    return this.appHttpRequest("GET", url, {});
}


  getRoles(orgId) {
    // const url = environment.baseUMApiUrl + '/v1/um/role?orgId='+orgId;
    // return this.http.get(url);
    return this.appHttpRequest("GET", "/role?orgId="+orgId, {});
  }

  getGroups(orgId) {
    // const url = environment.baseUMApiUrl + '/v1/um/group?orgId='+orgId;
    // return this.http.get(url);
    return this.appHttpRequest("GET", '/group?orgId='+orgId, {});
  }
  
  getUsers(orgId) {
    // const url = environment.baseUMApiUrl + '/v1/um/user?orgId='+orgId;
    // return this.http.get(url);
    return this.appHttpRequest("GET", '/user?orgId='+orgId, {});
  }

  getServices() {
    // const url = environment.baseUMApiUrl + '/v1/um/services';
    // return this.http.get(url);
    return this.appHttpRequest("GET", '/services', {});
  }

  getStats() {
    // const url = environment.baseUMApiUrl + '/v1/um/org/stats';
    // return this.http.get(url);
    return this.appHttpRequest("GET", '/org/stats', {});
  }

  getApiUsageStatistics() {
    const url = environment.apiGateway + "/gateway/statistics/all";
    // const url = "http://localhost:9090/statistics/all"
    const authCode = this.getHeaderToken();
    const authSelectedCode = this.getSelectedUserAuthToken();
  
    let headersConfig: any = {
      "X-AUTH-TOKEN": authCode,
    };
    if (authSelectedCode) {
     headersConfig["SELECTED-X-AUTH-TOKEN"] = authSelectedCode;
    }
    
    const headers = new HttpHeaders(headersConfig);
    return this.http.get(url, {headers, observe: 'response' });
  }

  requestApiLimitIncrease(api, limit) {
    let url = "/statistics/requestIncreaseLimit/" + api + "/" + limit;
    // url = "http://localhost:8082" + url;
    const authCode = this.getHeaderToken();
    const authSelectedCode = this.getSelectedUserAuthToken();
  
    let headersConfig: any = {
      "X-AUTH-TOKEN": authCode,
    };
    if (authSelectedCode) {
     headersConfig["SELECTED-X-AUTH-TOKEN"] = authSelectedCode;
    }
    
    const headers = new HttpHeaders(headersConfig);

    url = environment.apiGateway + "/user/" + url
    return this.http.get(url, {headers, observe: 'response' });
  }

  create(type, body) {
    // const url = 'http://localhost:8082/api/v1/um/' + type;
    // return this.http.post(url, body, {observe: "response"});    
    return this.appHttpRequest("POST", type, body); 
  }

  update(type, body) {
    // const url = 'http://localhost:8082/api/v1/um/' + type;
    // return this.http.put(url, body);    
    return this.appHttpRequest("PUT", type, body); 
  }

  isSessionValid() {
    // console.log("isSessionValid", localStorage.getItem("73S-Login-Time"));
    
    return !!localStorage.getItem("73S-Auth");
  }

  setIsValidSession(valid) {
    this.isValidSession = valid;
  }

  resetAuthToken() {
    if(localStorage.getItem("73S-Auth")) {
      localStorage.removeItem("73S-Auth");
    }
    this.isValidSession = false;
  }

  async logout(isRedirect?) {
    const details = this.getUserDetails();
    this.addUserAction("User Logout", details.id, details.userName, "User Management");
    
    if(isRedirect){
      await this.appHttpRequest("GET", "/logout", null, "gateway").toPromise().catch(e => {
        console.log("logged out", e)
      });
    }

    if(localStorage.getItem("73S-Auth")) {
      localStorage.removeItem("73S-Auth");
    }
    if(localStorage.getItem("73S-LogsOutIn")) {
      localStorage.removeItem("73S-LogsOutIn");
    }
    if(localStorage.getItem("73S-User")) {
      localStorage.removeItem("73S-User")
    }
    if(localStorage.getItem("73s-selected-vc")) {
      localStorage.removeItem("73s-selected-vc")
    }
    if(localStorage.getItem("73S-Selected-Auth")) {
      localStorage.removeItem("73S-Selected-Auth")
    }
    this.isValidSession = false;
      
  }

  getJWT_Token(tokenType, reqBody) {
    const authCode = this.getHeaderToken();
    const authSelectedCode = this.getSelectedUserAuthToken();
    const url = environment.apiGateway + '/gateway/api/v1/jwt/get/' + tokenType;  
  
    let headersConfig: any = {
      "X-AUTH-TOKEN": authCode,
    };
    if (authSelectedCode) {
     headersConfig["SELECTED-X-AUTH-TOKEN"] = authSelectedCode;
    }
    
    const headers = new HttpHeaders(headersConfig);
    return this.http.post(url, reqBody, {headers, observe: 'response'});
  }

  login(userName, pwd) {
    const url = environment.apiGateway + "/gateway/open/login"; 
    // const url = "http://localhost:9090/open/login"; 

    return this.http.post(url, {
        "username":userName,
        "password":pwd,
        "orgType": "vc"
      },{      
        observe: 'response'
      })
  }

  getOrgSpecificDetails(domainName){
    const url = environment.apiGateway + "/gateway/open/organization?domainName=" + domainName;
    return this.http.get(url,  {observe: 'response'});
  }

  singleSignIn(token) {
    let params = `token=${token}`;
    const url = environment.apiGateway + "/gateway/open/sso-login?" + params;
    return this.http.get(url,  {observe: 'response'}); 
  }

  resetPassword(pwd, oldPwd) {
    const url = environment.apiGateway + "/gateway/open/resetPassword";
  
    return this.http.post(url, {
        "username": this.getUserDetails().userId,
        "password": pwd,
        "oldPassword": oldPwd
      },{      
        observe: 'response'
      }
    )
  }

  forgotPassword(userName) {
    const url = environment.apiGateway + "/gateway/open/forgotPassword";
  
    return this.http.post(url, {
        "username": userName
      },{      
        observe: 'response'
      }
    )
  }

  manageSession(timeout) {
    // if(timeout > (10 * 60 * 1000)){

    //   setTimeout(() => {
    //     const lastActiveTimeStr = localStorage.getItem("73S-LastActiveTime");
    //     const lastActiveTime = lastActiveTimeStr? parseInt(lastActiveTimeStr, 10) : 0;

    //     if(((new Date().getTime()) - lastActiveTime) < 6900000){
    //       console.log("I am active from last few hours. Relogin!");
    //       this.reLogin();
          
    //     } else {          
    //       this.logout();
    //       this.router.navigateByUrl('/auth');
    //     }
    //   }, timeout - 5 * 60 * 1000);
      
    // } else {
    //   this.logout();
    //   this.router.navigateByUrl('/auth');
    // }
  }

  reLogin() {    
    const userName = localStorage.getItem("73S-VCP-userName");

    const url = environment.apiGateway + "/gateway/relogin/login";
  
    const authCode = this.getHeaderToken();

    this.http.post(url, 
      { "username": userName },
      { headers: {"X-AUTH-TOKEN": authCode}, observe: 'response' } 
    ).subscribe(result=>{

      localStorage.setItem("73S-Auth", result.body["response"]["X-AUTH-TOKEN"]);
      localStorage.setItem("73S-LogsOutIn", (new Date().getTime() + 7200000) + "");

      this.manageSession(7200000);

      // console.log("Successfully re-logged-in");

    }, error=>{
      console.log("Re-login is failed.", error);
      this.logout();
      this.router.navigateByUrl('/auth');
    })
  }

  testAuthToken() {
    return this.appHttpRequest("GET", "/test", {})
  }

  saveOrgDetails(orgName, details) {
    const org = this.getUserDetails().organization;
    const body = {
        email: this.getUserDetails().userId,
        orgName: orgName,
        type: org.orgType,
        userName: this.getUserDetails().userName,
        modifiesBy: this.getUserDetails().userId,
        details : details
    }
    return this.appHttpRequest("POST", '/org/details', body);

    // return this.http.post("http://localhost:8082/api/v1/um/org/details/", body, {observe: "response"})
  }

  getUserDetailsByDB_ID(userDBId) {
    // const url = "http://localhost:8082/api/v1/um/user/detailsById/" + userDBId
    // return this.http.get(url);
    return this.appHttpRequest("GET", "/user/detailsById/" + userDBId, {})
  }

  getUserDetailsByID(userId) {
    // const url = "http://localhost:8082/api/v1/um/user/details/" + userId
    // return this.http.get(url);
    return this.appHttpRequest("GET", "/user/details/" + userId, {})
  }

  setUserDetails(userDetails) {
    if(userDetails.lastActiveOn) {
      userDetails.lastActiveOn = moment(userDetails.lastActiveOn).format("ll");
    }
    localStorage.setItem("73S-User", JSON.stringify(userDetails));

    this.setSelectedUser(userDetails);
  }

  setSelectedUser(user) {
    localStorage.setItem("73s-selected-vc", JSON.stringify(user));

    this.ms.publish("selected-user-init", {});

    this.getMyOrgUsers();
  }

  getUserDetails() {
    if(localStorage.getItem("73S-User")) {
      return JSON.parse(localStorage.getItem("73S-User"));
    } else {
      return {};
    }    
  }

  getSelectedUserDetails() {
    if(localStorage.getItem("73s-selected-vc")) {
      return JSON.parse(localStorage.getItem("73s-selected-vc"));
    } else {
      return {};
    }    
  }

  get73SAuth(){
    if(localStorage.getItem("73S-Auth")) {
      return JSON.parse(localStorage.getItem("73S-Auth"));
    } else {
      return {};
    } 
  }

  // Check for login done for different source and redirect if valid
  async redirectToLoginSource(){
    const source = localStorage.getItem("73S-login-source");
    console.log("----------------- redirectToLoginSource", source);

    if(source) {
      const sourceInfo = JSON.parse(source);

      console.log("----------------- redirectToLoginSource", sourceInfo);

      // If login id done withing 20 seconds from the different source redirect time,
      // redirect url
      const curretTime = (new Date().getTime());
      const timeDifference = curretTime - sourceInfo.time;
      if(timeDifference < 25000){
        console.log("Redirecting to " + sourceInfo.url);
        // localStorage.removeItem("73S-login-source");

        let tokenType = sourceInfo.url;
        let reqBody = {}

        if(sourceInfo.url == "KB") {
          tokenType = "DOCUMENT_360";
          reqBody = { "username": this.getUserDetails().userName };
        }
        
        const res = await this.getJWT_Token(tokenType, reqBody).toPromise();
        const token = res.body["response"].token;
        
        let url = sourceInfo.url;
        if(sourceInfo.url == "KB") {
          url = environment.document360 + token;
        }

        console.log("----------- navigating to source page for which login is done", url);
        window.open(url, "_self");
      } else {
        localStorage.removeItem("73S-login-source");
      }
    }

  }

  setLoginSource(sourceURL) {
    console.log("----------------- setLoginSource", sourceURL);
    let url:any = "";

    if(sourceURL.includes('redirect-source=')) {
      url = window.location.hash.split('redirect-source=')[1];
    }

    if(!url || url.length == 0) return;

    const sourceInfo = {
      time: (new Date().getTime()) + "",
      url: url
    }
    localStorage.setItem("73S-login-source", JSON.stringify(sourceInfo));
  }

  getMyOrgUsers() {
    const myDetails = this.getSelectedUserDetails();
    this.getUsers(myDetails.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(user => user.id !== myDetails.id);;
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  getPortalUsers(userType) {
    return this.appHttpRequest("GET", "/user/" + userType, {})
    // const url = "http://localhost:8082/api/v1/um/user/" + userType
    // return this.http.get(url, {observe : "response"});
  }

  isPortalUser() {
    const details = this.getUserDetails();
    return details.organization.orgType === "vc" 
        || details.organization.orgType === "corp"
        || details.organization.orgType === "ext"
  }

  is73sAdmin() {
    const details = this.getUserDetails();
    return details.organization.orgType === "ai73s"
  }

  is73sAdminAndMasterUser() {
    const details = this.getUserDetails();
    return (details.organization.orgType === "ai73s" && ( details.userRole === 'admin' || details.role === 'admin') );
  }

  isSelectedUserCorp() {
    const details = this.getSelectedUserDetails();
    return details.organization.orgType === "corp"
  }

  isExternalUser() {
    const details = this.getSelectedUserDetails();
    return details && details.organization && details.organization.orgType === "ext"
  }

  getHeaderToken() {
    return localStorage.getItem("73S-Auth");
  }
  getSelectedUserAuthToken() {
    return localStorage.getItem("73S-Selected-Auth");
  }

  setSelectedUserAuthToken(value: string) {
    localStorage.setItem("73S-Selected-Auth", value);
  }

  appHttpRequest(type, url, body, api?) {

    let apiGatewayUrl;
    if(api==="gateway") {
      apiGatewayUrl = environment.apiGateway + "/gateway" + url;
    } else {
      apiGatewayUrl = environment.apiGateway + environment.umService + environment.apiVersion + "/um" + url;
    }
    const authCode = this.getHeaderToken();
    const authSelectedCode = this.getSelectedUserAuthToken();
  
    // Build headers conditionally
    let headersConfig: any = {
      "X-AUTH-TOKEN": authCode,
    };
   if (authSelectedCode) {
     headersConfig["SELECTED-X-AUTH-TOKEN"] = authSelectedCode;
   }

   const loggedInUser = this.getUserDetails();
   headersConfig["CONTEXT_ID"] = loggedInUser.id || '';
   headersConfig["OBJECT_ID"] = loggedInUser.id || '';
    
    const headers = new HttpHeaders(headersConfig);
  
    switch (type) {
      case "GET": 
        return this.http.get(apiGatewayUrl, { headers, observe: "response" });
      case "POST": 
        return this.http.post(apiGatewayUrl, body, { headers, observe: "response" });
      case "PUT": 
        return this.http.put(apiGatewayUrl, body, { headers, observe: "response" });
    }
  }

  getApplicationVersion() {
    const user = this.getSelectedUserDetails();
    if(user.organization.businessValuation) {
      return 2;
      
    } else if(user.organization.impairmentValuation) {
      return 3;

    } else if(user.organization.portfolioValuation) {
      return 4;
    }

    return 2;
  }

  addUserAction(type, id, name, module) {
    const userDetails = this.getUserDetails();
    let selectedUserDetails = this.getSelectedUserDetails();
    
    if(Object.keys(selectedUserDetails).length == 0){
      selectedUserDetails = userDetails;
    }
    
    const actionItems={
      type : type,
      id : id,
      name : name,
      module : module
    }
    
    if(Object.keys(userDetails).length > 0){
      this.saveUserAction({
        userId : selectedUserDetails.id,
        userName : selectedUserDetails.userName,
        action : actionItems,
        adminId : userDetails.userId,
        adminName : userDetails.userName,
        orgId : selectedUserDetails.organization.id,
        orgName : selectedUserDetails.organization.orgName,
        date : new Date()
      }).subscribe(res=>{
        console.log("Success", res);
      }, error=>{
        console.log("Error in adding the user action", error);  
      })
    }
  }

  saveUserAction(body) {
    const url ="/user_action_tracker/save_user_action";
    // const url = 'http://localhost:8082/api/v1/um/user_action_tracker/save_user_action';
     //return this.http.post(url, body, {observe: 'response'});  
     return this.appHttpRequest("POST", url , body)
  }

  isMasterUser() {
    const myDetails = this.getSelectedUserDetails();
    return myDetails.userRole === 'admin' || myDetails.role === 'admin';
  }

  isEZUser(){
    const myDetails = this.getSelectedUserDetails();
    if(myDetails.organization.id == "26bc1796-52e9-4666-b2f9-aeac4428959b"){
      return true;
    }

    return false;
  }
  getSingleSignOnURL() {
    let params = `returnUrl=${environment.portalUrl}`;
    const url = environment.apiGateway + "/gateway/open/login-url?" + params;
    return this.http.get(url,  {observe: 'response'}); 
  
  }

  async generateSelectedUserToken(userId, selectedUserDetails?){
    const tokenType = 'SELECTED_USER_73S'
    const reqBody = {
      userId: userId,
    }
    const res = await this.getJWT_Token(tokenType, reqBody).toPromise();
    if (!res?.body?.['response']?.token && res?.body?.['success']) {
      console.log('Failed to Load Selected User Auth token')
      this.toastService.openSnackBar('Failed to Load Selected User Auth token');
    }
    const token = res.body["response"]?.token;
    if (token) {
      if (this.getSelectedUserAuthToken()) {
        localStorage.removeItem("73S-Selected-Auth");
      }
      this.setSelectedUserAuthToken(token);
      if (selectedUserDetails) {
        this.setSelectedUser(selectedUserDetails);
      }

    }else {
      this.toastService.openSnackBar('Selected User Token is Not set!');
    }
  }
}