import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translation.service';
import { UserManagementService } from '../services/user-management.service';
import { ConfigurationDatabaseModal, ConfigurationModal } from './configuration.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  userInput: ConfigurationModal;
  recordsByOrgId: any;
  loading = true;
  customAttribute:any;
  qualitativeComment:any;

  defaultListOfAttributes = [{label: this.translateService.getLabel("total_number_of_employees")}, {label: this.translateService.getLabel("key_people")}, {label: this.translateService.getLabel("security_type")}, {label: this.translateService.getLabel("partners")}]

  configRecordsByFundId = [];

  oddIndexComments= [];
  evenIndexComments = [];
  isPanelOpen = false;

  sections = [
    {
      title: this.translateService.getLabel('valuation_summary'),
      selected: false,
      key: 'valuation_summary',
      expand:true,  
      disabled: false,
      items: [
        { 
          label: this.translateService.getLabel('weighted_implied_fair_enterprise_value'), 
          key: "enterpriseValue",
          selected: false,
          disabled: false,
        },
        { 
          label: this.translateService.getLabel('concluded_equity_value'), 
          key: "estimatedEquityValue",
          selected: false,
          disabled: false,

        },
        { 
          label: this.translateService.getLabel('concluded_adjusted_fair_value_of_equity'), 
          key: "finalAdjustedEquityVal",
          selected: false,
          disabled: false,

        },
        { 
          label: this.translateService.getLabel('fair_equity_value_of_the_stake'), 
          key: "stakeValue",
          selected: false,
          disabled: false,

        },
        { 
          label: this.translateService.getLabel('fair_value_of_investment_local_currency'), 
          key: "finalStakeValue",
          selected: false,
          disabled: false,

        },
        { 
          label: this.translateService.getLabel('fair_value_of_investment_reporting_currency'), 
          key: "finalStakeValueInReportingCurrency",
          selected: false,
          disabled: false,

        },
        { 
          label: this.translateService.getLabel('fair_equity_value_of_stake_per_share'), 
          key: "pricePerShare",
          selected: false,
          disabled: false,

        }
      ]
    },
    {
      title: 'Valuation Method',
      key: 'valuation_method',
      selected: false,  
      expand:true,  
      disabled: true,
      items: [
        { 
          label: 'Equity Value',
          key: 'totalEquityValue',
          selected: false,
          disabled: true
         },
        { 
          label: 'Enterprise Value',
          key: 'totalEnterpriseValue',
          selected: false,
          disabled: true
        }
      ]
    }
  ];

  sectionsClone = this.sections;

  selectedItems = []

  constructor(private utilService : UtilService,
    private ds: DataService,
    private ps: PortFolioSummaryServiceV2,
    private ums: UserManagementService,
    private activatedRoute: ActivatedRoute,
    public translateService : TranslateService,
    public modalService: NgbModal,
    private router : Router)
  { }

  ngOnInit(): void {
    this.userInput = new ConfigurationModal();
    console.log("-------------------", this.userInput);

    this.activatedRoute.paramMap.subscribe( async params => {
      const fundId = params.get("parentId");
      this.ps.selectedFundId = fundId;

      this.ps.initPortfolioSummaryWidgetdata();

      this.getConfigurationDetailsByFundId();

      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  togglePanel() {
    this.isPanelOpen = !this.isPanelOpen;
  }

  toggleSection(index: number) {
    this.sections[index].expand = !this.sections[index].expand
  }

  isIndeterminate(section: any): boolean {
    const selectedCount = section.items.filter(item => item.selected).length;
    return selectedCount > 0 && selectedCount < section.items.length;
  }

  isAllSelected(section: any): boolean {
    return section.items.every(item => item.selected);
  }

  toggleAll(section: any, checked: boolean) {
    section.selected = checked;
    this.userInput[section.key] = section.selected ? "Yes" : "No";

    section.items.forEach(item => {
      item.selected = checked
      this.userInput[item.key] = item.selected ? "Yes" : "No";
    });
  }

  updateSectionState(section: any, checked: boolean){
    section.selected = checked;
    this.userInput[section.key] = section.selected ? "Yes" : "No";
  }

  saveSelection() {
    this.selectedItems = [];
    this.isPanelOpen = false

    this.sections = cloneDeep(this.sectionsClone);

    this.sections.forEach(section => {
      this.userInput[section.key] = section.selected ? "Yes" : "No";

      section.items.forEach(item => {
        this.userInput[item.key] = item.selected ? "Yes" : "No";

        if (item.selected) {
          this.selectedItems.push({ sectionTitle: section.title, itemLabel: item.label });
        }
      });
    });
  }

  // Getting COnfig Details by Fund
  getConfigurationDetailsByFundId(){
    this.ds.recordsByCapturedForId(this.ps.selectedFundId).subscribe((res:any) => {
      this.configRecordsByFundId =  res.body.response;

      this.configRecordsByFundId.forEach(row => {
        if (row.value == "true" || row.value == "false" || (row.value).charAt(0) === '[') {
          this.userInput[row.configuration] = JSON.parse(row.value);
          row.value = JSON.parse(row.value)
        }
        else {
          this.userInput[row.configuration] = row.value;
        }
      });

      this.sections.forEach((section) => {
        const sectionExists = this.configRecordsByFundId.find(con => con.configuration == section.key)
        if(sectionExists){
          section.selected = sectionExists.value == "Yes" ? true : false;
          this.userInput[section.key] = section.selected ? "Yes" : "No";
        }
        section.items.forEach((item) => {
          const itemExists = this.configRecordsByFundId.find(con => con.configuration == item.key)
          if(itemExists){
            item.selected = itemExists.value == "Yes" ? true : false;
            this.userInput[item.key] = item.selected ? "Yes" : "No";
          }
        });
      });

      this.sectionsClone = cloneDeep(this.sections);

      this.saveSelection();

    }, error => {
      console.log("Failed to fetch config details for fund : " + this.ps.selectedFundId);
    })
  }

  async applyAll(configKey) {
    let allRows = [];
    let user = this.ums.getSelectedUserDetails();

    allRows.push(this.getDataModal(configKey, JSON.stringify(this.userInput[configKey]) + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    this.utilService.showLoadingPopup();

    try {
      const saveRes = await this.ds.insertConfigurationRecords(allRows).toPromise();

      if(allRows && allRows[0].value == '[]'){
        this.utilService.showMessage("Custom Attributes updated successfully", "Ok");
        return;
      }

      this.ds.applyAllFromConfiguration(configKey, this.ps.selectedFundId).subscribe(res => {
        console.log(configKey + " applied successfully");
        this.utilService.showMessage("Applied configuration successfully", "Ok");
      }, error => {
        console.log("Error while applying " + configKey);
        this.utilService.showMessage("Error while applying " + configKey, "Ok", true)
      })
    } catch(e) {
      this.utilService.showMessage("Failed apply all.", "Ok")
    }
  }


  // getrecordsByOrgId() {
  //   const user = this.ums.getSelectedUserDetails();
  //   this.ds.recordsByOrgId(user.organization.id).subscribe((res:any) => {
  //     this.recordsByOrgId =  res.body.response;
  //     this.recordsByOrgId.forEach((row,index) => {

  //       this.userInput[row.configuration] = JSON.parse(row.value);

  //     });
  //   }, error => {
  //    console.log("Failed to fetch config details for Org : " + user.organization.id);
  //   })

  // }

  save() {
    if(!this.configRecordsByFundId || this.configRecordsByFundId.length === 0){

      const allRows = this.prepareDatamodalForSave();
      this.insertConfigurationRecords(allRows);
    }
    else{
      let rowsToBeStored = [];

      const allRows = this.prepareDatamodalForSave();

      allRows.forEach(row => {
        const savedConfig = this.configRecordsByFundId.find(r => r.configuration == row.configuration);

        if(savedConfig) {
          if(savedConfig.value != this.userInput[savedConfig.configuration]){

            delete savedConfig.new;

            if (Array.isArray(this.userInput[savedConfig.configuration])) {
              savedConfig.value = JSON.stringify(this.userInput[savedConfig.configuration]);
            } else {
              savedConfig.value = this.userInput[savedConfig.configuration] + "";
            }

            rowsToBeStored.push(savedConfig);
          }
        } else {
          rowsToBeStored.push(row);
        }
      })

      this.insertConfigurationRecords(rowsToBeStored);
    }

  }

  userInputChange(input){
    this.userInput.applyChangeInValDateNav = input.value;
    this.userInput.applyChangeInTotalNav = input.value;
  }

  updateValuationMarkThreshHold(){
    if(!this.userInput.applyChangeInValDateNav && !this.userInput.applyChangeInTotalNav){
      this.userInput.valuationMarkThreshHold = false;
    }
  }

  insertConfigurationRecords(rows){

    this.ds.insertConfigurationRecords(rows).subscribe(res => {
      this.getConfigurationDetailsByFundId();
      this.utilService.showMessage("Configuration is saved successfully!", "Ok");
    }, error => {
      this.utilService.showMessage("Failed to save configuration", "Ok", true);
    })
  }

  prepareDatamodalForSave() {
    const user = this.ums.getSelectedUserDetails();

    const allRows = [];

    this.sections.forEach((section) => {
      const value = section.selected ? "Yes" : "No"
      allRows.push( this.getDataModal( section.key, value, "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId, user.organization.id, user.id, user.id));

      section.items.forEach((item) => {
        const value = item.selected ? "Yes" : "No"
        allRows.push( this.getDataModal( item.key, value, "VALUATION_SUMMARY", "EQUITY",
        "FUND",  this.ps.selectedFundId, user.organization.id, user.id, user.id)); 
      });
    });

    allRows.push(this.getDataModal("valuationMarkThreshHold", this.userInput.valuationMarkThreshHold, "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("applyChangeInValDateNav", this.userInput.applyChangeInValDateNav + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("changeInValDateNavPercentage", this.userInput.changeInValDateNavPercentage + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("applyChangeInTotalNav", this.userInput.applyChangeInTotalNav + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("changeInTotalNavPercentage", this.userInput.changeInTotalNavPercentage + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("enableImpliedDecline", this.userInput.enableImpliedDecline + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("valuationAndRiskReturn", this.userInput.valuationAndRiskReturn + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("roundingOff", this.userInput.roundingOff + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("multiplesComputation", this.userInput.multiplesComputation + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("filingVersionToBeApplied", this.userInput.filingVersionToBeApplied + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("dateComparisonToBeApplied", this.userInput.dateComparisonToBeApplied + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("customAttributes",JSON.stringify(this.userInput.customAttributes) + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("qualitativeComments",JSON.stringify(this.userInput.qualitativeComments) + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("poriThreshold",JSON.stringify(this.userInput.poriThreshold) + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("sizePremium", this.userInput.sizePremium + "", "VALUATION_SUMMARY", "EQUITY",
    "FUND", this.ps.selectedFundId,
    user.organization.id, user.id, user.id));

    allRows.push(this.getDataModal("calculatedNTM", this.userInput.calculatedNTM + "", "VALUATION_SUMMARY", "EQUITY",
      "FUND", this.ps.selectedFundId,
      user.organization.id, user.id, user.id));

    return allRows;
  }

  getDataModal(configuration, value, target, module,
    capturedFor, capturedForId,
    orgId, createdBy, lastModifiedBy) {

      const configDBModal = new ConfigurationDatabaseModal();

      configDBModal.setValues(configuration, value, target, module,
        capturedFor, capturedForId,
        orgId, createdBy, lastModifiedBy);

      return configDBModal;
  }

  createCustomAttribute(){
    if(!this.customAttribute){
      return;
    }

    if(!this.userInput.customAttributes) {
      this.userInput.customAttributes = [];
    }

    const combinedAttributesList = this.userInput.customAttributes.concat(this.defaultListOfAttributes);

    const attrExists = combinedAttributesList.find(attr => attr.label.toLowerCase() == this.customAttribute.toLowerCase());

    if (!attrExists) {
      this.userInput.customAttributes.push({
        id: this.utilService.getUUID(),
        label: this.customAttribute
      });
    }

    this.customAttribute = null;
  }

  createQualitativeComments(){
    if (!this.qualitativeComment) {
      return;
    }

    if(!this.userInput.qualitativeComments) {
      this.userInput.qualitativeComments = [];
    }

    const commentExists = this.userInput.qualitativeComments.find(c => c.label.toLowerCase() == this.qualitativeComment.toLowerCase());

    if (!commentExists) {
      this.userInput.qualitativeComments.push({
        id: this.utilService.getUUID(),
        label: this.qualitativeComment
      });
    }

    this.qualitativeComment = null;
  }

  deleteCustomAttribute(i){
    this.userInput.customAttributes.splice(i, 1);
  }

  deleteQualitativeComments(i){
    this.userInput.qualitativeComments.splice(i, 1);
  }

  closeThePage() {
    this.router.navigateByUrl('/portfolio/' + this.ps.selectedFundId);
  }

  reorderQualitativeComments(){
    this.evenIndexComments = [];
    this.oddIndexComments = [];
    this.userInput.qualitativeComments.forEach((row, index) => {
      if(row.hasOwnProperty("order")){
        row.order == 'left' ? this.evenIndexComments.push(row) : this.oddIndexComments.push(row)
      }
      else{
        if(index % 2 == 0 ){
          row.order = "left"
          this.evenIndexComments.push(row)
        }
        else{
          row.order = "right"
          this.oddIndexComments.push(row)
        }
      }
    })
  }


  openPopUpModel(content) {
    this.modalService.open(content, { centered: true, size: 'lg' })
  }

  async saveComments(){
    this.userInput.qualitativeComments = this.evenIndexComments.concat(this.oddIndexComments)
    this.save()
    this.utilService.closeAllPopups();
  }

  updateRoundingOffCondition(event: MatSlideToggleChange){
    this.userInput.roundingOff = event.source.checked ? "Yes" : "No";
  }
}
