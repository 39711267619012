declare var APP_ENV: any;

export const environment = {
  environment:APP_ENV.APP_ENVIRONMENT,
  production: APP_ENV.APP_PRODUCTION,
  
  apiVersion: "/v1",
  apiSAFAnswers: "/saf/answer",
  apiSAFNewForm: "/saf/form",

  apiGateway: APP_ENV.APP_API_GATEWAY,   //"https://vcpdevapims.azure-api.net", //"http://localhost:9090"
  vcpService: "/vcp/api", // VC Platform services
  safService: "/saf/api", // SAF services
  umService: "/user/api", // User Management services
  uamService:"/uam/api",  // User Management services 2.0
  valuationServiceSuffix: "/valuation/api",
  thirdPartyService: '/thirdpartyapi/api',
  exchangeRateApi: '/exchangeRate/singleExchangeRate',
  valDateExchangeRateAPI: '/valuation/currency-exchange',
  debtModelService: "/debtmodel/api",
  approvalSystemService: "/approval/api",

  twitterService: "https://ubuntuvm.centralus.cloudapp.azure.com/twitterapi/",
  
  portalUrl: APP_ENV.APP_PORTAL_URL,
  generalForms: "/forms",
  ivForms: "/iv-forms",
  corpForms: "/corp-forms",
  pvValuation: "/pv", //Portfolio Valuation
  impValuation: "/impairment", //Impairment Valuation
  projects: "/projects",
  safUrl: "/applicationform",
  imagePath: './',
  debtModel: '/debt-model/',
  simulation: '/simulation',

  document360: 'https://73-strings-kb.us.document360.io/jwt/authorize?code=',
  portcoSignIn: 'https://73stringsuat.b2clogin.com/73stringsuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNIN_OTP_PORTCO&client_id=30ce0ed9-f04b-4c8f-b5d3-8a40e235a208&nonce=defaultNonce&redirect_uri=https%3A%2F%2Faiplatform-uat.73strings.com&scope=openid&response_type=id_token&prompt=login',

  grapeCityLicense: "73 strings sas,458322554849124#B1XWKA7U0hmaapXWolWeOVzV6ljdpJjavJ6SRhkValjaO3mVJJVWWFjUERVQ6YFci3kQrMUcINGax5kVFJUSv94MCxmav3GbzQzYFNFdwsyRGdzTwgmbZRTeCJVR65WalR5byQ7SitkWwgzR5siVXZEewFnZkJzLrtWMRJFZhV7UVtCZjFmV7NXbZVkV9ZESQ3UUadTb5J4RClWTZhmcVh4SslERUxWaqZzckV7bxN4bOljcJxGZItERJhTQwQ4RUhlR6JWTIV7dOVUTyQ4YwpHdMtGc6cGcFRmVMx6cON5Q5ETRydlI0IyUiwiI5ADRxU4M6UjI0ICSiwSN6kTOwMTO4QTM0IicfJye#4Xfd5nIXR5QaJiOiMkIsIiNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiQzMzQzMwASNwIDM5IDMyIiOiQncDJCLiMVQTBycn9WayR7UgMzNiojIh94QiwiI4ITM9QDO4UTNyIzM8UDNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUi96KDF5dnJXaGdnUCFGUWFlb5MHaIRHbX9UeyYkc5UjSSFVMjNFRmJmWrkjYmF5dpBFe5BldHdWNwgkTpZEUKtESal4VjJzNqJVbClDbkZ4V53mRotmdFJUSY9ENlZkcsBnSGFkctJnbl9UR5EJU5V",
  apiDownload: '/files/download',
  translation_en_US: "https://73suatstorage.blob.core.windows.net/trading/en_US.json",
  translation_fr_FR: "https://73suatstorage.blob.core.windows.net/trading/fr_FR.json",
  currency_dashboard_template: "https://73backup.blob.core.windows.net/platform-files/Client Currency Rates Template.xlsx",
  // Add copied session parameters here. To copy session parameters, go to hosted platform URL, and press ctrl+shift+K or ctrl+alt+c
  // Paste the copied text like below after "dev" key
  
  // dev: {}
};
