<nav-menu-header headerLabel='Funds' headerToBeAppended="Configuration"></nav-menu-header>

<div class="card" *ngIf="!loading">
    <div class="row" style="margin: 0;">
        <div class="col">
            <mat-chip class="secondary-bg" style="margin-top: 10px;">Beta</mat-chip>
        </div>
        <div class="col align-right">
            <button type="submit" class="primaryBgButtonDeletePopUp" (click)="closeThePage()">
                {{translateService.getLabel("close")}}
            </button>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col-6">
                    <p>{{translateService.getLabel("application_of_thresholds_to_update_the_valuation_mark")}}</p>
                </div>
                <div class="col radio">
                    <mat-radio-group [(ngModel)]="userInput.valuationMarkThreshHold" (change)="userInputChange($event)" aria-label="Select an option">
                        <mat-radio-button [value]="true">{{translateService.getLabel("yes")}}</mat-radio-button>&nbsp;
                        <mat-radio-button [value]="false">{{translateService.getLabel("no")}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="row mt-1" *ngIf="userInput.valuationMarkThreshHold">
                <div class="col-6">
                    <span>{{translateService.getLabel("threshold_for_%_change_in_NAV_between_valuation_dates")}}</span>
                </div>
                <div class="col-2">
                    <mat-slide-toggle [(ngModel)]="userInput.applyChangeInValDateNav" (change)="updateValuationMarkThreshHold()"></mat-slide-toggle>
                </div>
                <div class="col" modal-textbox>
                    <mat-form-field *ngIf="userInput.applyChangeInValDateNav" class="align-right">
                        <input matInput [(ngModel)]="userInput.changeInValDateNavPercentage"
                            (input)="utilService.acceptOnlyNumbers($event,  userInput, 'changeInValDateNavPercentage')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3" *ngIf="userInput.valuationMarkThreshHold">
                <div class="col-6">
                    <span>{{translateService.getLabel("threshold_for_change_in_NAV_as_a_%_of_total_portfolio_NAV")}}</span>
                </div>
                <div class="col-2">
                    <mat-slide-toggle [(ngModel)]="userInput.applyChangeInTotalNav" (change)="updateValuationMarkThreshHold()"></mat-slide-toggle>
                </div>
                <div class="col">
                    <mat-form-field *ngIf="userInput.applyChangeInTotalNav" class="align-right">
                        <input matInput [(ngModel)]="userInput.changeInTotalNavPercentage"
                            (input)="utilService.acceptOnlyNumbers($event, userInput, 'changeInTotalNavPercentage')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col-6">
                    <p>{{translateService.getLabel("custom_attributes")}}</p>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">
                    <mat-form-field>
                        <input matInput placeholder="{{translateService.getLabel('enter_custom_attributes')}}" [(ngModel)]="customAttribute">
                    </mat-form-field>&nbsp;&nbsp;
                    <button mat-stroked-button class="create-btn" (click)="createCustomAttribute()">{{translateService.getLabel("create")}}</button> &nbsp;
                    <button mat-stroked-button class="create-btn" (click)="applyAll('customAttributes')">{{translateService.getLabel("apply_all")}}</button>
                </div>
            </div><br>
            <div class="row">
                <div *ngFor="let da of defaultListOfAttributes">
                    <div class="col col-space" modal-textbox>
                        <mat-form-field class="mat-input-field">
                            <input matInput readonly [(ngModel)]="da.label" style="color:black">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngFor="let ca of userInput.customAttributes ; let i = index">
                    <div class="col col-space" modal-textbox>
                        <mat-form-field class="mat-input-field">
                            <input matInput [(ngModel)]="ca.label" style="color:black">
                            <span matSuffix style="cursor: pointer;"><mat-icon (click)="deleteCustomAttribute(i)">delete_outline</mat-icon></span>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col-6">
                    <p>{{translateService.getLabel("qualitative_analysis_comments")}}</p>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">
                    <mat-form-field>
                        <input matInput placeholder="{{translateService.getLabel('enter_qualitative_analysis_comments')}}" [(ngModel)]="qualitativeComment">
                    </mat-form-field>&nbsp;&nbsp;
                    <button mat-stroked-button class="create-btn" (click)="createQualitativeComments()">{{translateService.getLabel("create")}}</button> &nbsp;
                    <button mat-stroked-button class="create-btn" (click)="applyAll('qualitativeComments')">{{translateService.getLabel("apply_all")}}</button> &nbsp;
                    <button mat-stroked-button class="create-btn" (click)="reorderQualitativeComments(); openPopUpModel(comments)">{{translateService.getLabel("order")}}</button>
                </div>
            </div><br>
            <div class="row">
                <div *ngFor="let cm of userInput.qualitativeComments ; let i = index">
                    <div class="col col-space" modal-textbox>
                        <mat-form-field class="mat-input-field">
                            <input matInput [(ngModel)]="cm.label" style="color:black">
                            <span matSuffix style="cursor: pointer;"><mat-icon (click)="deleteQualitativeComments(i)">delete_outline</mat-icon></span>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col">
                    <p>{{translateService.getLabel("enable_implied_decline")}}</p>
                </div>
                <div class="col">
                    <mat-slide-toggle [(ngModel)]="userInput.enableImpliedDecline"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col">
                    <p>{{translateService.getLabel("valuation_and_risk_return_framework")}}</p>
                </div>
                <div class="col">
                    <mat-slide-toggle [(ngModel)]="userInput.valuationAndRiskReturn"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div>
                <div class="row">
                    <div class="col">
                        <p style="display: inline-flex; align-content: center; flex-wrap: wrap;">{{translateService.getLabel("rounding_off")}}
                            <mat-icon matTooltip="When the rounding option for line items is enabled, the corresponding values are rounded off. If the feature is disabled subsequently,
                             rounding with persist for the already onboarded dates; but will not be applied on rollover/resubmission."
                            style="font-size: large;padding: 1px 0px 0px 5px;">info_outline</mat-icon>
                        </p>
                    </div>
                    <div class="col">
                        <mat-slide-toggle [ngModel]="userInput.roundingOff == 'Yes'" (change)="updateRoundingOffCondition($event)"></mat-slide-toggle>
                    </div>
                </div>
                <div *ngIf="userInput.roundingOff == 'Yes'">
                    <div style="display: flex;flex-direction: column;">
                        <div style="display: inline-flex;">
                            <div class="custom-field" (click)="togglePanel()">
                                <span style="display: inline-flex;align-items: center;cursor: pointer;justify-content: space-between;width: 100%;">
                                    <span class="field-palceHolder">{{translateService.getLabel("select_line_items_for_rounding_off")}}</span>
                                    <mat-icon style="padding-left: 8px;" *ngIf="!isPanelOpen">keyboard_arrow_down</mat-icon>
                                    <mat-icon style="padding-left: 8px;" *ngIf="isPanelOpen">keyboard_arrow_up</mat-icon>
                                </span>
                            </div>
                            <button mat-stroked-button class="create-btn" (click)="saveSelection()">{{translateService.getLabel("apply")}}</button>
                        </div>

                        <div style="display: flex; flex-wrap: wrap;">
                            <span *ngFor="let selectedItem of selectedItems" class="selected-checkbox-cell checkbox-label" style="margin: 2vh 8px 0 0;">
                              {{ selectedItem.sectionTitle }} : {{ selectedItem.itemLabel }}
                            </span>
                        </div>
                          
                        <div *ngIf="isPanelOpen" class="floating-panel">
                            <div *ngFor="let section of sectionsClone;let i = index">
                                <div style="display: flex;justify-content: space-between;" class="checkbox-row" (click)="toggleSection(i)">
                                  <mat-checkbox class="checkbox-label"
                                    [checked]="isAllSelected(section)"
                                    [indeterminate]="isIndeterminate(section)"
                                    [disabled]="section.disabled"
                                    (change)="toggleAll(section, $event.checked)">
                                    {{ section.title }}
                                  </mat-checkbox>
                                  <mat-icon *ngIf="section.expand">keyboard_arrow_down</mat-icon>
                                  <mat-icon *ngIf="!section.expand">keyboard_arrow_up</mat-icon>
                                </div>
                              
                                <div *ngIf="section.expand">
                                  <div style="padding-left: 2.5vw;" class="checkbox-row" *ngFor="let item of section.items">
                                    <mat-checkbox class="checkbox-label"
                                      [(ngModel)]="item.selected"
                                      [disabled]="item.disabled"
                                      (change)="updateSectionState(section)">
                                      {{ item.label }}
                                    </mat-checkbox>
                                  </div>
                                </div>
                            </div>
                                                       
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col-6">
                    <p>{{translateService.getLabel("application_of_customised_settings_for_multiples_computation_under_trading_comps")}}</p>
                </div>
                <div class="col radio">
                    <mat-radio-group [(ngModel)]="userInput.multiplesComputation" aria-label="Select an option">
                        <mat-radio-button [value]="true">{{translateService.getLabel("yes")}}</mat-radio-button>&nbsp;
                        <mat-radio-button [value]="false">{{translateService.getLabel("no")}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="mt-1" *ngIf="userInput.multiplesComputation">
                <div class="row">
                    <div class="col">
                        <span>{{translateService.getLabel("filing_version_to_be_applied")}}</span>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col radio">
                        <mat-radio-group [(ngModel)]="userInput.filingVersionToBeApplied" aria-label="Select an option">
                            <mat-radio-button value="Latest Fully Reported">{{translateService.getLabel("latest_fully_reported")}}</mat-radio-button>
                            <div class="space-between-radio-btn"></div>
                            <mat-radio-button value="Latest Version">{{translateService.getLabel("latest_version")}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col">
                        <span>{{translateService.getLabel("date_comparison_to_be_applied_to")}}</span>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col radio">
                        <mat-radio-group [(ngModel)]="userInput.dateComparisonToBeApplied" aria-label="Select an option">
                            <mat-radio-button value="Filing date">{{translateService.getLabel("filing_date")}}</mat-radio-button>
                            <div class="space-between-radio-btn"></div>
                            <mat-radio-button value="Period date">{{translateService.getLabel("period_date")}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col">
                    <p>{{translateService.getLabel("pori_threshold")}}</p>
                </div>
                <div class="col" modal-textbox>
                    <mat-form-field class="align-right">
                        <input matInput [(ngModel)]="userInput.poriThreshold"
                            (input)="utilService.acceptOnlyNumbers($event,  userInput, 'poriThreshold')">
                    </mat-form-field>&nbsp;&nbsp;<span>Months</span>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col">
                    <p>{{translateService.getLabel("discount_rate_size_premium_automation")}}</p>
                </div>
                <div class="col">
                    <mat-slide-toggle [(ngModel)]="userInput.sizePremium" [matTooltip]="'Please note that activating this feature will automatically compute the Size Premium in Income Approach - Discount Rate Tab'"
                    [matTooltipPosition]="'above'" ></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow">
        <div class="card-body configuration-div">
            <div class="row">
                <div class="col">
                    <p>Enable Calculated NTM for Trading Comps</p>
                </div>
                <div class="col">
                    <mat-slide-toggle [(ngModel)]="userInput.calculatedNTM"></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>


    <div style="text-align: right">
        <button mat-raised-button class="primaryBgButtonDeletePopUp" type="submit" (click)="save()">Save</button>
    </div>
</div>

<div class="card" *ngIf="loading">
    <div class="card box-shadow">
        <div class="card-body configuration-div" style="text-align: center;">
            Loading
        </div>
    </div>
</div>

<ng-template #comments let-comments>
    <div class="modal-header">
      <div class="modal-title">
        <h4>Qualitative Comments</h4>
      </div>
      <button #closeBtn type="button" class="close" aria-label="Close" (click)="comments.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body comments">
        <app-qualitative-analysis-comments [qualitativeComments]="userInput.qualitativeComments" [evenIndexComments]="evenIndexComments" [oddIndexComments]="oddIndexComments"> </app-qualitative-analysis-comments>
    </div>
    <div class="modal-footer">
        <button class="saveBtn" type="submit" style="float:right" (click)="saveComments(); comments.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>
