<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' headerToBeAppended="CurrencyDashboard"
  (click)="navigateToPortfolio()"></nav-menu-header>

<div class="page">
  <div class="page-inner" style="padding-top: 0.5%">
    <div class="page-header">
      <nav class="page-header-navigation">
        <ul>
          <li *ngFor="let item of valuationNavigationLinks; index as i" [class.active]="item.isActive">
            <a (click)="switchTab(item.key, i);" [class.disabled]="item.isActive">{{item.title}}</a>
          </li>
        </ul>
      </nav>
    </div>

    <h6 class="header">{{ translateService.getLabel("currency_dashboard") }}</h6>
    <div style="text-align: right; margin: 5px 10px;">
      <span class="col-2" style="float:left;text-align: left;">
        <div class="primary-color marginBottom">
          {{ translateService.getLabel("choose_fx_rate") }}
        </div>
        <div>
          <!-- [ngClass]="{'disabledClick': !editLockService.lockStatus}"  -->
          <div (click)="editCustomPopupModal(fxRateEdit)"
            style="text-decoration: underline;cursor: pointer; color: darkblue;">

            <ng-template [ngIf]="averageTypeSelection[activeTab.toUpperCase()].periodType == 'SPOT'"
              [ngIfElse]="otherAverageType">
              {{translateService.getLabel(averageTypeSelection[activeTab.toUpperCase()].periodType)}}
            </ng-template>

            <ng-template #otherAverageType>
              {{averageTypeSelection[activeTab.toUpperCase()].period}} -
              {{translateService.getLabel(averageTypeSelection[activeTab.toUpperCase()].periodType)}} -
              {{translateService.getLabel(averageTypeSelection[activeTab.toUpperCase()].frequency)}}
            </ng-template>
          </div>
        </div>
      </span>
      <span class="col-2" style="float:left;text-align: left;">
        <div class="marginBottom">
          {{ translateService.getLabel("choose_view_option") }}
        </div>
        <mat-form-field class="mat-form-pad-adj">
          <mat-select name="View" [(ngModel)]="selectedView">
            <mat-option value="Platform View" *ngIf="systemValue == 'System'" (click)="platformView = 'platform'; changeCurrency('Platform View')">{{
              translateService.getLabel("platform") }}</mat-option>
            <mat-option value="Clients View" *ngIf="systemValue == 'System' || systemValue == 'noSystem'"
              (click)="platformView = 'client_excel'; changeCurrency('Clients View')">{{ translateService.getLabel("client_excel") }}</mat-option>
            <mat-option value="Oanda View" *ngIf="systemValue == 'System'" (click)="platformView = 'oanda'; changeCurrency('Oanda View')">{{
              translateService.getLabel("Oanda") }}</mat-option>
          </mat-select>
        </mat-form-field>
      </span>

      <!-- <span class="col-2" style="float:left;text-align: left; margin: 1%;">
        <a style="cursor: pointer; color: #007bff; text-decoration: underline;"
          (click)="downloadCurrencyDashbaordExcelTemplate()">
          {{translateService.getLabel("download_template")}}
        </a>
      </span> -->

      <div>
        <input type="file" style="display: none;" accept=".csv,.xls,.xlsx" #fileInput class="custom-file-input"
          (change)="fileUpload($event)" />
      </div>
      <ng-template [ngIf]="fileTobeUploaded && fileTobeUploaded.name && platformView == 'client_excel'">
        <span>
          {{fileTobeUploaded.name}}
        </span>
      </ng-template>

      <!-- <button mat-raised-button class="primary-bg p-button" [disabled]="isUpdateInProgress(dashboard[activeTab])"
        (click)="downloadExcel()" [class.disabled]="isDownloading" style="margin: 10px 10px;">
        {{ translateService.getLabel("download") }}
      </button> -->

      <button mat-raised-button *ngIf="platformView == 'client_excel'" class="primary-bg p-button"
        [disabled]="isUpdateInProgress(dashboard[activeTab])" (click)="clickOnFileInput(fileInput)"
        style="margin: 10px 10px;">
        {{ translateService.getLabel("select_file_to_upload") }}
      </button>

      <button mat-raised-button class="primary-bg p-button" style="margin: 10px 10px;" (click)="updateCurrencyFxRate()"
        [disabled]="isUpdateInProgress(dashboard[activeTab])">
        {{ translateService.getLabel("save") }}
      </button>

      <button mat-raised-button class="primary-light-bg p-button" style="margin: 10px 10px;" (click)="closeThePage()">
        {{ translateService.getLabel("close") }}
      </button>
    </div>

    <div style="text-align: end; padding-right: 10px;">
      <button mat-raised-button class="primary-light-bg p-button btn-pagination" [disabled]="pageNumber == 1" (click)="pageDecrement()">
        <
      </button>
      <span style="font-size: 15px;"> {{pageNumber}} </span>
      <button mat-raised-button class="primary-light-bg p-button btn-pagination" [disabled]="unfilteredTransCount < 100" (click)="pageIncrement()">
        >
      </button>
    </div>

    <ng-template [ngIf]="platformView == 'platform'">
      <ng-template [ngIf]="activeTab=='debt'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">Date</div>

            <div class="col">{{translateService.getLabel("local_currency")}}</div>
            <div class="col">{{translateService.getLabel("reporting_currency")}}</div>
            <div class="col align-right">{{translateService.getLabel("platform_fx_rate")}}</div>
            <div class="col-2">{{translateService.getLabel("manual_override")}}</div>
            <div class="col align-right">{{translateService.getLabel("concluded_fx")}}</div>

          </div>
          <div class="row table_row_border" *ngFor="let data of dashboard.debt;let index;">
            <div class="col-1">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>

            <div class="col">{{data.sourceCurrency}}</div>
            <div class="col">{{data.targetCurrency}}</div>
            <div class="col align-right">
              <span [RawDataDirective]="data.fxRate">
                {{data.fxRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col-2" style="text-align: center;">
              <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                <input matInput type="number" [(ngModel)]="data.manualOverride">
              </mat-form-field>
            </div>
            <div class="col align-right" *ngIf="!data.manualOverride">
              <span [RawDataDirective]="data.concludedRate">
                {{data.concludedRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col align-right" *ngIf="data.manualOverride">
              <span [RawDataDirective]="data.manualOverride">
                {{data.manualOverride | number: '1.1-100' }}
              </span>
            </div>

          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="activeTab=='equity'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;" *ngIf="dashboard">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">{{ translateService.getLabel("date") }}</div>
            <!-- <div class="col-1">Company</div> -->
            <div class="col">{{ translateService.getLabel("local_currency") }}</div>
            <div class="col">{{ translateService.getLabel("reporting_currency") }}</div>
            <div class="col align-right">{{ translateService.getLabel("platform_fx_rate") }}</div>
            <div class="col-2">{{ translateService.getLabel("manual_override") }}</div>
            <div class="col align-right">{{ translateService.getLabel("concluded_fx") }}</div>
            <!-- <div class="col">Type</div> -->
          </div>
          <div class="row table_row_border" *ngFor="let data of getViewData(dashboard[activeTab]);let index;">
            <div class="col-1">
              {{data.currencyDate | date: 'yyyy-MM-dd'}}
            </div>
            <!-- <div class="col-1">{{data.source}}</div> -->
            <div class="col">{{data.sourceCurrency}}</div>
            <div class="col">{{data.targetCurrency}}</div>
            <div class="col align-right">
              <span [RawDataDirective]="data.fxRate">
                {{data.fxRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col-2" style="text-align: center;">
              <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                <input matInput type="number" [(ngModel)]="data.manualOverride">
              </mat-form-field>
            </div>
            <div class="col align-right">

              <ng-template [ngIf]="!data.updating && !data?.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.concludedRate | number: '1.1-1'}}
                </span>
              </ng-template>
              <ng-template [ngIf]="!data.updating && data?.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.manualOverride | number: '1.1-100'}}
                </span>
              </ng-template>

              <ng-template [ngIf]="data.updating">
                {{ translateService.getLabel("updating") }}
              </ng-template>
            </div>
            <!-- <div class="col">{{data.type}}</div> -->
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="activeTab=='monitoring'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">Date</div>

            <div class="col">Local Currency</div>
            <div class="col">Reporting Currency</div>
            <div class="col align-right">Platform Fx Rate</div>
            <div class="col-2">Manual Override</div>
            <div class="col align-right">Concluded Fx</div>

          </div>
          <ng-template [ngIf]="dashboard.monitoring && dashboard.monitoring.length > 0">
            <div class="row table_row_border" *ngFor="let data of dashboard.monitoring">
              <div class="col-1">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>

              <div class="col">{{data.sourceCurrency}}</div>
              <div class="col">{{data.targetCurrency}}</div>
              <div class="col align-right">
                <span [RawDataDirective]="data.fxRate">
                  {{data.fxRate | number: '1.1-1' }}
                </span>
              </div>
              <div class="col-2" style="text-align: center;">
                <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                  <input matInput type="number" [(ngModel)]="data.manualOverride">
                </mat-form-field>
              </div>
              <div class="col align-right" *ngIf="!data.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.concludedRate | number: '1.1-1' }}
                </span>
              </div>
              <div class="col align-right" *ngIf="data.manualOverride">
                <span [RawDataDirective]="data.manualOverride">
                  {{data.manualOverride | number: '1.1-100' }}
                </span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>

    <ng-template [ngIf]="platformView == 'oanda'">
      <ng-template [ngIf]="activeTab=='debt'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">Date</div>

            <div class="col">Local Currency</div>
            <div class="col">Reporting Currency</div>
            <div class="col align-right">Platform Fx Rate</div>
            <div class="col-2">Manual Override</div>
            <div class="col align-right">Concluded Fx</div>

          </div>
          <div class="row table_row_border" *ngFor="let data of dashboard.debt;let index;">
            <div class="col-1">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>

            <div class="col">{{data.sourceCurrency}}</div>
            <div class="col">{{data.targetCurrency}}</div>
            <div class="col align-right">
              <span [RawDataDirective]="data.fxRate">
                {{data.fxRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col-2" style="text-align: center;">
              <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                <input matInput type="number" [(ngModel)]="data.manualOverride">
              </mat-form-field>
            </div>
            <div class="col align-right" *ngIf="!data.manualOverride">
              <span [RawDataDirective]="data.concludedRate">
                {{data.concludedRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col align-right" *ngIf="data.manualOverride">
              <span [RawDataDirective]="data.manualOverride">
                {{data.manualOverride | number: '1.1-100' }}
              </span>
            </div>

          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="activeTab=='equity'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;" *ngIf="dashboard">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">{{ translateService.getLabel("date") }}</div>
            <!-- <div class="col-1">Company</div> -->
            <div class="col">{{ translateService.getLabel("local_currency") }}</div>
            <div class="col">{{ translateService.getLabel("reporting_currency") }}</div>
            <div class="col align-right">{{ translateService.getLabel("platform_fx_rate") }}</div>
            <div class="col-2">{{ translateService.getLabel("manual_override") }}</div>
            <div class="col align-right">{{ translateService.getLabel("concluded_fx") }}</div>
            <!-- <div class="col">Type</div> -->
          </div>
          <div class="row table_row_border" *ngFor="let data of getViewData(dashboard[activeTab]);let index;">
            <div class="col-1">
              {{data.currencyDate | date: 'yyyy-MM-dd'}}
            </div>
            <!-- <div class="col-1">{{data.source}}</div> -->
            <div class="col">{{data.sourceCurrency}}</div>
            <div class="col">{{data.targetCurrency}}</div>
            <div class="col align-right">
              <span [RawDataDirective]="data.fxRate">
                {{data.fxRate | number: '1.1-1' }}
              </span>
            </div>
            <div class="col-2" style="text-align: center;">
              <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                <input matInput type="number" [(ngModel)]="data.manualOverride">
              </mat-form-field>
            </div>
            <div class="col align-right">

              <ng-template [ngIf]="!data.updating && !data?.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.concludedRate | number: '1.1-1'}}
                </span>
              </ng-template>
              <ng-template [ngIf]="!data.updating && data?.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.manualOverride | number: '1.1-100'}}
                </span>
              </ng-template>

              <ng-template [ngIf]="data.updating">
                {{ translateService.getLabel("updating") }}
              </ng-template>
            </div>
            <!-- <div class="col">{{data.type}}</div> -->
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="activeTab=='monitoring'">
        <div class="table-rounded-corners" style="padding: 0px 14px;border-radius: 6px;">
          <div class="row table-custom-header table_header_style table_row_border">
            <div class="col-1">Date</div>

            <div class="col">Local Currency</div>
            <div class="col">Reporting Currency</div>
            <div class="col align-right">Platform Fx Rate</div>
            <div class="col-2">Manual Override</div>
            <div class="col align-right">Concluded Fx</div>

          </div>
          <ng-template [ngIf]="dashboard.monitoring && dashboard.monitoring.length > 0">
            <div class="row table_row_border" *ngFor="let data of dashboard.monitoring">
              <div class="col-1">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>

              <div class="col">{{data.sourceCurrency}}</div>
              <div class="col">{{data.targetCurrency}}</div>
              <div class="col align-right">
                <span [RawDataDirective]="data.fxRate">
                  {{data.fxRate | number: '1.1-1' }}
                </span>
              </div>
              <div class="col-2" style="text-align: center;">
                <mat-form-field style="width: 30%" style="text-align: left;width: 100%;">
                  <input matInput type="number" [(ngModel)]="data.manualOverride">
                </mat-form-field>
              </div>
              <div class="col align-right" *ngIf="!data.manualOverride">
                <span [RawDataDirective]="data.concludedRate">
                  {{data.concludedRate | number: '1.1-1' }}
                </span>
              </div>
              <div class="col align-right" *ngIf="data.manualOverride">
                <span [RawDataDirective]="data.manualOverride">
                  {{data.manualOverride | number: '1.1-100' }}
                </span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>

    <ng-template [ngIf]="platformView == 'client_excel'">
      <!-- <ng-template [ngIf]="activeTab=='debt'">
            <div class="col-5 table-rounded-corners" style="padding: 0px 0px;border-radius: 6px;">
              
                <div class="row table-custom-header table_header_style table_row_border table_header">
                  <div class="col">Date</div>
                  <div class="col align-right">Fx</div>
                  <div class="col align-right">Rate</div>
                </div>
                <div class="row table_row_border table_header" *ngFor="let data of getViewData(dashboard[activeTab])">
                  <div class="col">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>
                  <div class="col align-right">{{data.sourceCurrency}}/{{data.targetCurrency}}</div>
                  <div class="col align-right">{{data.concludedRate | number: '1.1-1'}}</div>
                </div>
              
            </div>
          </ng-template>-->

      <ng-template [ngIf]="activeTab=='equity'">
        <div class="col-5 table-rounded-corners" style="padding: 0px 0px;border-radius: 6px;" *ngIf="dashboard">
          <!-- <div class="col-8"> -->
          <div class="row table-custom-header table_header_style table_row_border table_header">
            <div class="col">{{ translateService.getLabel("date") }}</div>
            <div class="col align-right">{{ translateService.getLabel("fx") }}</div>
            <div class="col align-right">{{ translateService.getLabel("rate") }}</div>
          </div>
          <div class="row table_row_border" *ngFor="let data of getViewData(dashboard[activeTab])">
            <div class="col">{{data.currencyDate}}</div>
            <div class="col align-right">{{data.sourceCurrency}}/{{data.targetCurrency}}</div>
            <div class="col align-right">
              <span [RawDataDirective]="data.concludedRate">
                {{data.concludedRate | number: '1.1-1'}}
              </span>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </ng-template>

      <!-- <ng-template [ngIf]="activeTab=='monitoring'">
            <div class="col-5 table-rounded-corners" style="padding: 0px 0px;border-radius: 6px;">
              
                <div class="row table-custom-header table_header_style table_row_border table_header">
                  <div class="col">Date</div>
                  <div class="col align-right">Fx</div>
                  <div class="col align-right">Rate</div>
                </div>
                <div class="row table_row_border table_header" *ngFor="let data of getViewData(dashboard[activeTab])">
                  <div class="col">{{data.currencyDate | date: 'yyyy-MM-dd'}}</div>
                  <div class="col align-center">{{data.sourceCurrency}}/{{data.targetCurrency}}</div>
                  <div class="col align-center">{{data.concludedRate | number: '1.1-1'}}</div>
                </div>
              
            </div>
          </ng-template> -->
    </ng-template>

    <!-- <table class="table">
            <tr class="grey-bg">
                <th>Source / Target</th>
                <th>Date</th>
                <th class="rightAlign">Platform Fx Rate</th>
                <th style="text-align: center;">Manual Override</th>
                <th></th>
            </tr>
            <tr *ngFor="let currency of exchange_rate; index as i">
                <td>{{ currency.source }} / {{currency.target}}</td>
                <td>{{ currency.date }}</td>
                <td class="rightAlign">{{ currency.rate | number:'1.3-3'}}</td>

                <td style="text-align: center;">
                    <mat-form-field style="width: 30%" style="text-align: left;"><input matInput type="number" [(ngModel)]="currency.newRate"></mat-form-field>
                </td>

                <td><i class="far fa-save" style="cursor: pointer;" (click)="updateCurrency(currency)"></i></td>
            </tr>
        </table> -->
  </div>
</div>


<!-- Type Popup -->
<ng-template #fxRateEdit let-fxRateEditPopup>
  <div class="modal-header">
    <div class="modal-title">
      <h4>{{ translateService.getLabel("add_custom_period") }}</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="{{ translateService.getLabel('close') }}"
      (click)="fxRateEditPopup.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row" style="margin:1vh 1vh 2vh 1vh">
      <div class="col-4">{{ translateService.getLabel("averaging_tenure") }}
        <span matTooltip="Tenure for which the average of data points will be fetched" matTooltipPosition="right">
          <i class="fa fa-info-circle"></i>
        </span>
      </div>
      <div class="col-3">
        <mat-form-field *ngIf="averageTypeEditModel.periodType !== 'SPOT'">
          <input matInput type="number" (input)="acceptOnlyPositiveNumbers($event, averageTypeEditModel, 'period')"
            [(ngModel)]="averageTypeEditModel.period">
        </mat-form-field>

        <span *ngIf="averageTypeEditModel.periodType == 'SPOT'"> NA </span>
      </div>

    </div>
    <div class="row" style="margin:1vh">
      <div class="col-4">{{ translateService.getLabel("period_type") }}
        <span matTooltip="Unit for the Averaging Tenure" matTooltipPosition="right">
          <i class="fa fa-info-circle"></i>
        </span>
      </div>
      <div class="col-3">
        <mat-form-field class="mat-form-pad-adj">
          <mat-select name="type" [(ngModel)]="averageTypeEditModel.periodType">
            <mat-option value="SPOT">Spot</mat-option>
            <mat-option value="YEARS">Years</mat-option>
            <mat-option value="QUARTERS">Quarterly</mat-option>
            <mat-option value="MONTHS">Months</mat-option>
            <mat-option value="WEEKS">Weeks</mat-option>
            <mat-option value="DAYS">{{ translateService.getLabel("days") }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin:2vh 1vh 1vh 1vh">
      <div class="col-4">{{ translateService.getLabel("frequency") }}
        <span matTooltip="Frequency of fetching the data points" matTooltipPosition="right">
          <i class="fa fa-info-circle"></i>
        </span>
      </div>
      <div class="col-3">
        <mat-form-field class="mat-form-pad-adj" *ngIf="averageTypeEditModel.periodType !== 'SPOT'">
          <mat-select name="type" [(ngModel)]="averageTypeEditModel.frequency">
            <mat-option value="YEARLY">Yearly</mat-option>
            <mat-option value="QUARTERLY">Quarterly</mat-option>
            <mat-option value="MONTHLY">Monthly</mat-option>
            <mat-option value="WEEKLY">Weekly</mat-option>
            <mat-option value="DAILY">{{ translateService.getLabel("daily") }}</mat-option>
          </mat-select>
        </mat-form-field>

        <span *ngIf="averageTypeEditModel.periodType == 'SPOT'"> NA </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-primary" [disabled]="isUpdateInProgress(dashboard[activeTab]) || !averageTypeEditModel.period || averageTypeEditModel.period < 0"
      (click)="updateAverageType(); fxRateEditPopup.dismiss('Cross click');">
      {{ translateService.getLabel("save") }}
    </button>
  </div>
</ng-template>


<a style="display: none;" #downloadLink></a>