import { Component, OnDestroy, OnInit } from '@angular/core';
import { FundListService } from '../fund-list.service';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioService } from '../../portfolio.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { cloneDeep } from 'lodash';
import { colors } from 'src/app/utils/colors';
import worldMap from '../../world-data.data';
import { MapChart } from 'angular-highcharts';
import { Chart } from 'angular-highcharts';
import { PortfolioUtilService } from '../../portfolio-util.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-custom-summary-component',
  templateUrl: './custom-summary-component.component.html',
  styleUrls: ['./custom-summary-component.component.scss']
})
export class CustomSummaryComponentComponent implements OnInit, OnDestroy {
  geography
  sector
  partnersList
  refreshingSummaryTable;

  isMasterUser;

  portfolioSummary;
  noData = false;
  unsavedChanges = false;
  fundSelectFlag = false;

  componentDestroyed = false;

  fundCompanyList : any = [];

  masterPartnersList = [];

  fundFlagStates: boolean[] = [];

  disabledStates: boolean[] = [];
  index:any;

  filters = {
    userSelection: { 
      funds: [], 
      geography: [], 
      sector: [], 
      companies: [],
      reportingCurrency: "USD",
      customAttribute: [],
      valuationDate:"latest_valuation_date"
    }
  }

  clickedFundId = "";

  selectedReportId;
  selectedReport;

  mapData = {
    value: 'currentVal',
    name:'Current Valuation',
    data:[]
  }

  allValDates = [];
  geographicalFormData = [];
  mapChart;
  columnChart;

  fundIdList = [];

  reportingCurrency = "USD";

  allPortfolioData;
  customAttributeList : any[];

  customAttributeNames = []
  customAttributeNameToKeyMapping = {};

  selectedCustomAttributeNameToValueMapping = {};

  constructor(public portfolioService: PortFolioService, 
    public fundService: FundListService, private router: Router,
    private utilService: UtilService, private ums : UserManagementService,
    private activatedRoute: ActivatedRoute,
    private ds: DataService, private portfolioUtil : PortfolioUtilService,
    public currencyExchangeService : CurrencyExchangeService, 
    public translateService : TranslateService) 
  { }


  ngOnDestroy(): void {
    this.utilService.closeAllPopups();

    if(this.portfolioService.allCompanyValuationDatesAvailable){
      this.portfolioService.allCompanyValuationDatesAvailable.unsubscribe();
    }
    this.componentDestroyed = true;    
  }

  ngOnInit() {
    this.isMasterUser = this.ums.isMasterUser();

    this.activatedRoute.paramMap.subscribe(params => {
      this.selectedReportId = params.get("reportId");

      this.ds.getFundDetails(this.selectedReportId).subscribe(res => {
        this.selectedReport = res.body["response"];
      })
      
      this.ds.getWidgetDataFromDB("YELLOW_REPORT", this.selectedReportId).subscribe(res => {
        const dataToBeSaved = res.body["response"][0].widgetData;

        dataToBeSaved.filters.userSelection.companies.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        this.filters = dataToBeSaved.filters;

        if(!this.filters.userSelection.hasOwnProperty('customAttribute') ){
          this.filters.userSelection.customAttribute = [];
        }

        if(!this.filters.userSelection.reportingCurrency){
          this.filters.userSelection.reportingCurrency = "USD"
        }
        
        this.getFundIdList();

        this.getCustomAttributes()

        this.reportingCurrency = this.filters.userSelection.reportingCurrency;

        // this.portfolioSummary = dataToBeSaved.portfolioSummary;

        // this.mapChart = this.prepareMapData();
        // this.columnChart = this.prepareColumnChart();

        this.initializeGeographySector();
        
        this.onClickOfFilter();
      }, err => {
        this.noData = true;
      })
      
      this.getFundList();
    });
    
  }

  getFundIdList(){
    this.fundIdList = this.filters.userSelection.funds.map(element => {
      return element.id;
    })
    
    const reqBody = {
      "fundIds": this.fundIdList
    }

    this.ds.getDistinctValDates(reqBody).subscribe(res => {
      const apiResponse = res.body["response"];
      this.allValDates = ["latest_valuation_date"]
      if(apiResponse){
        apiResponse.forEach(a => this.allValDates.push(a))
      }
    }, error => {
      console.log("Failed to fetch Distinct valuation dates");
    })
  }

  getCustomAttributes(){

    let companiesArray = [];

    this.filters.userSelection.companies.forEach(company => {
      companiesArray.push(company.id);
    })

    this.ds.getCustomAttributes(companiesArray).subscribe(res => {

      this.customAttributeList = res.body["response"];

      this.customAttributeList.forEach(item => {
        if (this.customAttributeNames.indexOf(item.attributeName) == -1) {
          this.customAttributeNames.push(item.attributeName);
        }
      });

      this.customAttributeNames.forEach(name => {
        const keysForTheAttributeName = this.customAttributeList.filter(attr => attr.attributeName == name)
        .map(attr => attr.attributeKey);

        this.customAttributeNameToKeyMapping[name] = keysForTheAttributeName ? keysForTheAttributeName : [];
      })

      this.initFormattedSelectedCustomAttributeNamesToValuesMapping()
      
    }, 
    error => {
      console.log("Failed to fetch Custom Attributes");
    })
    
  }

  getFormattedSelectedCustomAttributeNames() {
    // Remove custom attribute name from selected list 
    // if any values are not selected for that name.

    this.filters.userSelection.customAttribute = 
        this.filters.userSelection.customAttribute.filter(attr => {
          return attr.attributeValues && attr.attributeValues.length > 0
        });

    // Create unique list of attribute names
    const customAttributeNames = []
    this.filters.userSelection.customAttribute.forEach(item => {
      if (customAttributeNames.indexOf(item.attributeName) == -1) {
        customAttributeNames.push(item.attributeName);
      }
    });

    return customAttributeNames;
  }

  initFormattedSelectedCustomAttributeNamesToValuesMapping(){

    const customAttributeNames = this.getFormattedSelectedCustomAttributeNames();

    this.selectedCustomAttributeNameToValueMapping = {};

    customAttributeNames.forEach(name => {
      // Get all the attributes with same names from selected list
      const attrsByNames = this.filters.userSelection.customAttribute.filter(attr => attr.attributeName == name)
      
      const valuesToNameMapping = [];

      // Create unique list of attribute values for each attribute name.
      attrsByNames.forEach(attr => {
        attr.attributeValues.forEach(v => {
          const mappingExists = valuesToNameMapping.find(mappedValue => mappedValue == v);
          if(!mappingExists) {
            valuesToNameMapping.push(v);
          }
        })
      })

      this.selectedCustomAttributeNameToValueMapping[name] = valuesToNameMapping ? valuesToNameMapping : [];
    })
  }

  getFundList(){
    const orgId = this.ums.getSelectedUserDetails().organization.id;
    const userId = this.ums.getSelectedUserDetails().id;

    this.ds.getFundList(orgId, userId).subscribe(data =>{

      const fundData = data.body["response"];

      fundData.forEach(fund =>{
        let obj = {
          id: fund.id,
          name: fund.name,
          selected: false,
          clicked: false, 
          companyList : null
        } as any;

        this.fundCompanyList.push(obj);
      })

      this.fundCompanyList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    });
  }
  
  getCompanyListFromFunds(selectedFundInChipList){
    this.clickedFundId = selectedFundInChipList.id;
    const fundInFullList = this.fundCompanyList.find(f => f.id == this.clickedFundId);

    // if(fundInFullList.companyList){

      
    //   fundInFullList.companyList.sort((a, b) =>
    //   {
    //     let index1 = a.valuationDates.length - 1;
    //     let index2 = b.valuationDates.length - 1;
    //     return new Date(a.valuationDates[index1].date).valueOf() - new Date(b.valuationDates[index2].date).valueOf()
    //   })
      
    //   return;
    // }

    const assessorUserId = this.ums.getSelectedUserDetails().id;
    const approvalStatus = "ALL";

    this.utilService.showLoadingPopup();

    this.ds.getCompanyListFromFunds(assessorUserId, approvalStatus, this.clickedFundId).subscribe(data =>{
      const companyList = data.body["response"];

      companyList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

      companyList.forEach((company) =>{
        company.fundName = selectedFundInChipList.name;
        company.fundId = this.clickedFundId;

        //check in companies array if companies already present ,if not push the company
        if(!this.filters.userSelection.companies.some(obj => obj.id === company.id)){
          this.filters.userSelection.companies.push(company);
        }
        
      })

      this.filters.userSelection.companies.sort((a, b) => {
        let str1 = a.name.toLowerCase().trim();
        let str2 = b.name.toLowerCase().trim();
        return str1 > str2 ? 1 : -1;
      });

      fundInFullList.companyList = companyList;

      fundInFullList.companyList.sort((a, b) =>
      {
        let index1 = a.valuationDates.length - 1;
        let index2 = b.valuationDates.length - 1;
        return new Date(a.valuationDates[index1].date).valueOf() - new Date(b.valuationDates[index2].date).valueOf()
      })

      this.fundSelectFlag = false
      this.utilService.closeAllPopups();
      this.getCustomAttributes()

    }, error => {
      this.utilService.closeAllPopups();
    });
  }

  getAllFundCompanies(fund) {
    fund.allCompanies = !fund.allCompanies;
    const assessorUserId = this.ums.getSelectedUserDetails().id;
    const approvalStatus = "ALL";
    if (fund.allCompanies) {
      this.utilService.showLoadingPopup();
      this.ds.getCompanyListFromFunds(assessorUserId, approvalStatus, fund.id).subscribe(data =>{
        const companyList = data.body["response"];

        companyList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        companyList.forEach((company) =>{
          company.fundName = fund.name;
          company.fundId = fund.id;
  
          if(!this.filters.userSelection.companies.some(obj => obj.id === company.id)){
            this.filters.userSelection.companies.push(company);
          }  
        })
        this.utilService.closeAllPopups();
      },
      error => {
        this.utilService.closeAllPopups();
      })
    }
  }
  

  select(type, obj) {
    if(type == 'FUND') {
      const fund = this.filters.userSelection.funds.find(f => f.id == obj.id)

      if(!fund) {
        this.filters.userSelection.funds.push(obj);
      }
      
      this.getFundIdList();

    } else if(type == 'COMPANY'){
      const comp = this.filters.userSelection.companies.find(c => c.id == obj.id);

      if(!comp) {
        obj.fundId = this.clickedFundId;
        this.filters.userSelection.companies.push(obj);

        this.initializeGeographySector();
      }
    } else if(type == 'GEOGRAPHY'){
      if(this.filters.userSelection.geography.indexOf(obj) == -1){
        this.filters.userSelection.geography.push(obj)
      }
    } else if(type == 'SECTOR'){
      if(this.filters.userSelection.sector.indexOf(obj) == -1){
        this.filters.userSelection.sector.push(obj)
      }
    }
    else if(type == "CURRENCY"){
      this.filters.userSelection.reportingCurrency = obj.id;
      this.reportingCurrency = obj.id;
    }
    else if(type == "VALUATION_DATE"){
        this.filters.userSelection.valuationDate = obj;
    }
    else if(type == "CUSTOM_ATTRIBUTES"){
      const attrName = cloneDeep(obj);
      const attrKeys = this.customAttributeNameToKeyMapping[attrName] 
      ? this.customAttributeNameToKeyMapping[attrName] : [];

      attrKeys.forEach(key => {
        const selectedAttribute = this.filters.userSelection.customAttribute.find(item => item.attributeKey === key);

        const attr = this.customAttributeList.find(item => item.attributeKey === key);
      
        //check for if attributeValues length is not equal to obj attributeValues length it  will assign objects attributeValues
        if (selectedAttribute) {
          selectedAttribute.attributeValues = attr.attributeValues;
        } 
        else {
          this.filters.userSelection.customAttribute.push(attr);
        }
      })

      this.initFormattedSelectedCustomAttributeNamesToValuesMapping()
    }
  }

  removeAttributeValues(attrName, value){
    const attrsByName = this.filters.userSelection.customAttribute.filter(attr => attr.attributeName == attrName);
    
    attrsByName.forEach(attr => {
      const valueIndexFound = attr.attributeValues.findIndex(v => v == value);
      if(valueIndexFound >= 0){
        attr.attributeValues.splice(valueIndexFound, 1);
      }
    })

    this.initFormattedSelectedCustomAttributeNamesToValuesMapping();
  }

  //Name Parameter is for CUSTOM_ATTRIBUTES
  removeSelection(type, index, name?) {
    if(type == 'FUND') {
      const deletedObjs = this.filters.userSelection.funds.splice(index, 1);
      if(deletedObjs[0].id == this.clickedFundId){
        this.clickedFundId = "";
      }
      this.filters.userSelection.companies.forEach((comp, index) => {
        if(comp.fundId == deletedObjs[0].id) {
          this.filters.userSelection.companies.splice(index, 1);
        }
      })
      this.initializeGeographySector();

    } else if(type == 'COMPANY'){
      this.filters.userSelection.companies.splice(index, 1);

      this.initializeGeographySector();

    } else if(type == 'GEOGRAPHY'){
      this.filters.userSelection.geography.splice(index, 1);

    } else if(type == 'SECTOR'){
      this.filters.userSelection.sector.splice(index, 1);
    }
    else if (type == 'CUSTOM_ATTRIBUTES') {
      const attrKeys = this.filters.userSelection.customAttribute.map(attr => attr.attributeKey)
      attrKeys.forEach(key => {
        const indexFound = this.filters.userSelection.customAttribute.findIndex(attr => attr.attributeName == name)
        if(indexFound >= 0){
          this.filters.userSelection.customAttribute.splice(indexFound, 1);
        }
      })

      this.initFormattedSelectedCustomAttributeNamesToValuesMapping()
    }
  }

  initializeGeographySector() {
    this.geography = this.filters.userSelection.companies
      .filter((comp) => comp.geography)
      .map((comp) => { return comp.geography.name })

    this.sector = this.filters.userSelection.companies
      .filter((comp) => comp.sector)
      .map((comp) => { return comp.sector })

    //Find unique values
    this.sector = this.sector.filter((sector, index, array) => { return array.indexOf(sector) === index })
    this.geography = this.geography.filter((geography, index, array) => { return array.indexOf(geography) === index })
  }

  onClickOfFilter() {
    const apiReq = { 
      funds : [], 
      reportingCurrency: this.reportingCurrency, 
      widgetId: this.selectedReportId,
      
      dataToBeSaved: {
        filters: this.filters,
        portfolioSummary: null
      }
    };

    this.filters.userSelection.funds.forEach(selectedFund => {
      const fund = {fundId: selectedFund.id, name: selectedFund.name, allCompanies:selectedFund.allCompanies, companies: null };

      this.filters.userSelection.companies.forEach(selectedComp => {
        
        if(selectedComp.fundId == selectedFund.id) {

          const selectedCompSector = selectedComp.sector;
          const selectedCompGeography = selectedComp.geography?.name;

          if(
            (this.filters.userSelection.geography.length == 0 || this.filters.userSelection.geography.indexOf(selectedCompGeography) >= 0)
            && (this.filters.userSelection.sector.length == 0 || this.filters.userSelection.sector.indexOf(selectedCompSector) >= 0) ) 
          {
          
            if(!fund.companies) fund.companies = [];
            
            const comp = cloneDeep(selectedComp);
            comp.investmentDate = selectedComp.valuationDates[selectedComp.valuationDates.length - 1].date
            comp.valuationDates = selectedComp.valuationDates.map(vd => {
              return {
                "id": vd.id,
                "valuationDate": vd.date
              }
            })
            
            fund.companies.push(comp);
          }
        }
      })

      if(fund.companies) {
        apiReq.funds.push(fund);
      }
    })

    this.noData = false;

    this.allPortfolioData = undefined
    
    apiReq["userId"] = this.ums.getSelectedUserDetails().id;

    this.ds.getYellowReportSummaryV2(apiReq).subscribe(res=> {
      this.reportingCurrency = this.filters.userSelection.reportingCurrency;
      const apiData = res.body["response"];

      apiData.totalAggregations.totalIRR = "loading";
      apiData.currentPortfolio.aggregations.totalIRR = "loading";
      apiData.realisedPortfolio.aggregations.totalIRR = "loading";

      apiData.currentPortfolio.portfolioData.forEach(comp => {
        comp.valuesInPortfolioCurrency.grossIRR = "loading";
      })

      apiData.realisedPortfolio.portfolioData.forEach(comp => {
        comp.valuesInPortfolioCurrency.grossIRR = "loading";
      })

      this.allPortfolioData = cloneDeep(apiData);
    }, err => {
      this.noData = true;
    })
    this.unsavedChanges = false;
  }

  detectChanges() {
    this.unsavedChanges = true;
  }

  fundSelected(){
    this.fundSelectFlag = true
  }

  emittingActionOnPortfolio(event){
    this.portfolioUtil.emittingActionOnPortfolio(event, this.selectedReportId);
  }

  initColumnChart(columnChartData){
    const __this = this;

    const columnChart = new Chart({
      chart: { 
        renderTo: 'column-chart-container',
        type: 'column' 
      },
      title: { text: '' },
      xAxis: {
          categories: columnChartData.map( data => data.sectorName),
          crosshair: true
      },
      yAxis:{ title:{ text: "Value (" + this.reportingCurrency + " Mn)" } },
      credits: { enabled: false },
      tooltip: {
        formatter: function() {
          return __this.reportingCurrency + " " + Number(this.y.toFixed(1)).toLocaleString() + " Mn"
        }
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        { name: 'Previous Valuation', data: columnChartData.map( data => data.prevValDate), color: colors.chartPreviousValuationColor},
        { name: 'Current Valuation', data:  columnChartData.map( data => data.currValDate), color: colors.chartCurrentValuationColor}
      ] as any
    })

    return columnChart;

  }

  prepareColumnChart(){
    let columnChartData = [];

    const sectors = [];

    this.portfolioSummary.portfolioData.forEach(form =>{
      if(form.sector){
        if(sectors.indexOf(form.sector) == -1){
          sectors.push(form.sector);
        }
      }
    });

    sectors.forEach(sector => {
      const formsWithSameSector = this.portfolioSummary.portfolioData.filter(form => form.sector == sector);
      let prevValDate: number = 0;
      let currValDate: number = 0;


      formsWithSameSector.forEach((comp) => {
        const currentValDateStakeValue = comp.valuesInPortfolioCurrency.stakeValue;
        // const changeInStakeValue = comp.stakeVal * comp.changeInValPercentage

        // const prevValDateStakeValue = this.utilService.getValidNumber((currentValDateStakeValue - comp.changeInStakeValue) * comp['exchangeRate']);
        const prevValDateStakeValue = comp.valuesInPortfolioCurrency.prevStakeValue;

        currValDate += currentValDateStakeValue;

        prevValDate += prevValDateStakeValue;
      })

      columnChartData.push({sectorName: sector, prevValDate: prevValDate, currValDate: currValDate});
    });

    columnChartData = columnChartData.sort((sec1, sec2) => {
      return (sec2.currValDate - sec1.currValDate);
    }).splice(0, 6);

    return this.initColumnChart(columnChartData);
  }

  initWorldMapChart(mapData){
    const mapChart = new MapChart({
      chart: { 
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          name: mapData.name,
          joinBy: ['iso-a3', 'code3'],
          data: mapData.data,
          color: colors.chartCurrentValuationColor,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormatter: function() {
                return this.name+ ":" + Number(this.z.toFixed(1)).toLocaleString()
              }
          }
      }] as any
    });    
    return mapChart
  }

  updateWorldMap(){
    this.mapData.data = [];
    this.geographicalFormData.forEach((formData)=>{

      const data = { code3:'', name:'', z: 0 }
      
      if(this.mapData.value == "changeInVal"){
        this.mapData.name = "Change In Valuation";
        let changeInValCountryWise = 0;

        formData.forms.map((form) => {
          changeInValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = changeInValCountryWise;
      }
      else if(this.mapData.value == "prevQuarterVal"){
        this.mapData.name = "Previous Quarter Valuation";
        let prevQuarValCountryWise = 0;

        formData.forms.map((form) => {
          prevQuarValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = prevQuarValCountryWise;
      }
      else if(this.mapData.value == "noOfComp"){
        this.mapData.name = "Total Number of Companies";
        let totalNoOfComp = formData.forms.length? formData.forms.length: 0 ;
        data.z = totalNoOfComp;
      }
      else{
        this.mapData.name = "Current Valuation";
        let totalValCountryWise = 0;

        formData.forms.forEach((form) => {
          totalValCountryWise += form.valuesInPortfolioCurrency.stakeValue;
        });
        data.z = totalValCountryWise;
      }

      data.code3 = formData.countryCode;
      data.name = formData.countryName;
      this.mapData.data.push(data);
    });

    return this.initWorldMapChart(this.mapData);
  }

  prepareMapData(){
    
    const formsArray = this.portfolioSummary.portfolioData;

    let codeCountryName = [];

    this.geographicalFormData = [];

    formsArray.map(form => {
      if(form.geography != undefined){
        codeCountryName.push({
          alias: form.geography.cbAlias, 
          countryName: form.geography.name
        });
      }
    })

    let alias = '';
    let countryName = '';
    let filteredData = codeCountryName.filter((data)=>{
      if(alias == ''){
        alias=data.alias;
        countryName = data.countryName;
      }
      else{
        if(alias == data.alias && countryName == data.countryName)
          return false
        else
          alias = data.alias
          countryName = data.countryName
          return true
      }

    })

    let geoCode = codeCountryName.map((code) => code.alias)

    let geo = geoCode.filter( (code, index) => {
      return geoCode.indexOf(code) == index;
    })
  
    geo.map( geoCode =>{
      let formData = [];
      formsArray.filter(form =>{
        if(form.geography != undefined){
          if(form.geography.cbAlias == geoCode){
            formData.push(form);
          }
        }
      });
      const geographicalData = {
        countryName:'',
        countryCode:'', 
        forms:[]
      }

      let countryName;
      filteredData.map( data =>{
        if(data.alias == geoCode){
          countryName = data.countryName;
        }
      })
      geographicalData.countryName = countryName;
      geographicalData.countryCode = geoCode;
      geographicalData.forms = formData;
      this.geographicalFormData.push(geographicalData);
    })
    return this.updateWorldMap();
  }
}
